<template>
    <div class="header-date" v-if="date" v-bind:class="{'weekend' : date.isoWeekday() === 6 || date.isoWeekday() === 7, 'today' : date.dayOfYear() === today.dayOfYear()}">
        <div class="today-top"></div>
        <div class="top">{{date.format('dd').toUpperCase()}}</div>
        <div class="bottom">{{date.date()}}</div>
    </div>
</template>

<script>
    export default {
        name: "headerdate",
        data(){
            return {
                date: null,
                today: this.$helpers.getMomentTZStartOfDay(),
            }
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                }
            }
        },
        props: {
            state: Object,
        },
    }
</script>

<style scoped>
    .header-date{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--contrast-1);
        min-width: 300px;
    }
    .header-date.weekend{
        background: var(--sub-menu);
    }
    .header-date.today{
        background: #e86d1d;
    }

    .header-date .today-top{
        display: none;
        position: absolute;
        left: 0;
        background: #e86d1d;
        top: -5px;
        height: 5px;
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .header-date.today .today-top{
        display: flex;
    }

    .top,.bottom{
        position: absolute;
        top: 1px;
        width: 100%;
        left: 0;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: var(--contrast-5);

    }
    .top{
        font-family: DistrictProBold;
        font-weight: 800;
    }
    .header-date.weekend *, .header-date.today *{
        color: white;
    }

    .bottom{
        top: initial;
        bottom: 1px;
    }


    .format-bottom{
        top: initial;
        bottom: 0;
    }
</style>